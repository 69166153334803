import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';
import { EmergencyDisclaimer } from '../components/common';
import './index.scss';

class Index extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        settings: {
          data: settingsData,
        },
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      bannerStyle = null,
      bannerTheme = null,
      emergencyTitle = null,
      emergencyDisclaimerText = null,
    } = settingsData;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    const hasEmergencyDisclaimer = Boolean(emergencyTitle && emergencyTitle.text && emergencyDisclaimerText && emergencyDisclaimerText.html);
    return (
      <Layout location={location} seoData={seoData}>
        <div className={`home-content${hasEmergencyDisclaimer ? ' has-disclaimer' : ''}`}>
          { hasEmergencyDisclaimer && (
            <EmergencyDisclaimer
              bannerTheme={bannerTheme}
              bannerStyle={bannerStyle}
              title={emergencyTitle}
              content={emergencyDisclaimerText}
            />
          )}
          <SliceZone
            allSlices={body}
            siteMetadata={siteMetadata}
            location={location}
          />
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        blogSlug,
      }
    },
    settings: prismicSettings {
      data {
        bannerTheme: banner_theme
        bannerStyle: banner_style
        emergencyTitle: emergency_title {
          text
        }
        emergencyDisclaimerText: emergency_disclaimer_text {
          html
        }
      }
    }
    page: prismicHome {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicHomeBodyHomeHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              photo {
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicHomeBodyIllustratedActions {
            id
            sliceType: slice_type
            items {
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              illustrationStyle: illustration_style
              theme
              hoverDescription: hover_description {
                text
              }
              actionLabel: action_label {
                text
              }
              actionLink: action_link {
                target
                slug
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicHomeBodyTextAndLinksBlock {
            id
            sliceType: slice_type
            primary {
              theme
              headline {
                text
              }
              subtitle {
                text
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                slug
                url
                type
                linkType: link_type
              }
            }
            items {
              questionLabel: question_label {
                text
              }
              questionLink: question_link {
                url
              }
            }
          }
          ... on PrismicHomeBodyLatestPostsBlock {
            sliceType: slice_type
            id
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
            }
          }
          ... on PrismicHomeBodyContactFormBlock {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`;
